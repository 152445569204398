import { Box, Image, Stack, Text, VStack, Link, Container } from "@chakra-ui/react";
import tg from 'assets/tg.svg';
function Features() {
  return (
    <Stack
      direction="column"
      spacing={{ base: "4rem", md: "8rem", xl: "6rem" }}
      color="white"
      width="80%"
      minHeight="80vh"
    >
      <Container
        marginTop={{
          base: "-75px",
          md: "-130px",
        }}
        width='100%'
        background='rgb(31 9 19 / 80%)'
        maxWidth='100%'
        borderRadius='32px'
        zIndex='4'
      >
        <Text fontSize={{ base: "1.3rem", md: "3rem" }} marginTop={{
          base: "5rem",
          md: "10rem",
          }} fontWeight='900'>
          Welcome to the Future of
        </Text>
        <Text fontSize={{ base: "1.5rem", md: "3rem" }} marginTop="-5px" fontWeight='900'>
          Dice Gaming!
        </Text>
        <Text fontSize={{ base: "24px" }} marginTop="2rem" fontWeight='600'>
          Get ready for a gaming revolution!
        </Text>
        <Text fontSize={{ base: "0.85rem", md: "1.25rem" }} marginTop="1rem" marginBottom='60px' marginInline={{ base: "5%", md: "15%" }}>
          Our upcoming dice games promise an immersive experience with captivating visuals, innovative gameplay, and cross-platform magic. Be among the first to explore the next dimension of gaming, connect with a vibrant community, and enjoy rewards that elevate your gaming journey. Stay tuned for the dice revolution – your adventure begins here! 🎲🌟 #DiceFuture #GamingRevolution
        </Text>
      </Container>
    
      <Container width='100%' maxWidth='100%' paddingBottom='100px'>
        <Text width='100%' marginBottom='10px'>Stay connected with the community on:</Text>
        <Link href='https://t.me/+0MWYdZDQUCY2NTQ0' isExternal><Image src={tg} width='50px' margin='0 auto' href='google.com'/></Link>
      </Container>
    </Stack>
  );
}

export default Features;
