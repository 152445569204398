import {
  Box,
  Button,
  HStack,
  VStack,
  Image,
  IconButton,
  Text,
  useDisclosure,
  Link,
  CloseButton,
  Select,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { useAccount, useConnect, useDisconnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useScroll } from "framer-motion";
import { ExpandMore } from "@mui/icons-material";
import polygon_icon from "assets/polygon_logo_wo_text.svg";
import logo from "assets/logo.svg";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const { address, isConnected } = useAccount();
  const [selectedPolygonValue, setSelectedPolygonValue] =
    useState("Polygon Mumbai");
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();

  function getAddressShortString() {
    return `${address?.slice(0, 4)}...${address?.slice(-4)}`;
  }

  const mobileNav = useDisclosure();

  console.log("location", location);
  if (location.pathname == "/extension-installed") {
    return null;
  }
  
  function getButtons() {
    const arr = [{
      text: 'About',
      link: 'https://zkdice.gitbook.io/welcome-to-zkdice/'
    }, {
      text: 'Learn game',
      link: 'https://zkdice.gitbook.io/welcome-to-zkdice/about-the-game/game-rules'
    },{
      text: 'Roadmap',
      link: 'https://zkdice.gitbook.io/welcome-to-zkdice/roadmap'
    }]
    const nodes = []
    for(let i=0; i<arr.length; ++i) {
      const item = arr[i]
      nodes.push(<Link href={item.link} isExternal><Button
          variant="link"
          colorScheme="white"
          _hover={{
            textDecoration: "none",
          }}
          fontWeight='normal'
          size={{ base: "xs", md: "md", lg: "lg" }}
        >
          {item.text}
        </Button></Link>)
    }
    return nodes
  }
  
  const NavMenuButton: typeof MenuButton = (props) => {
		return (
			<MenuButton
				bg="whiteAlpha.300"
				_hover={{ bg: "whiteAlpha.200" }}
				_active={{ bg: "whiteAlpha.200" }}
				as={Button}
				rightIcon={<ExpandMore />}
        style={{ color: "inherit" }}
				{...props}
			>
				{selectedPolygonValue}
			</MenuButton>
		);
	};

  const MobileNavContent = (
    <VStack
      pos="absolute"
      top={0}
      left={0}
      right={0}
      display={mobileNav.isOpen ? "flex" : "none"}
      flexDirection="column"
      p={2}
      pb={4}
      height="fit-content"
      spacing={3}
      bg="black"
      rounded="sm"
      shadow="sm"
    >
      <CloseButton
        aria-label="Close menu"
        justifySelf="self-start"
        onClick={mobileNav.onClose}
      />
      {getButtons()}
    </VStack>
  );
  return (
    <HStack
      position="fixed"
      top="32px"
      backdropFilter="auto"
      backdropBlur="0.5rem"
      boxShadow="md"
      bgColor="rgba(27, 8, 18, 0.5)"
      height="56px"
      minWidth="100%"
      maxWidth="100%"
      color="white"
      paddingInline={{ base: "1rem", md: "3rem", lg: "4rem", xl: "6rem" }}
      paddingY={{ md: "1rem", lg: "1rem", xl: "2rem" }}
      justifyContent="space-between"
      zIndex={10}
      spacing="0rem"
      borderStyle="solid"
      borderTop="0rem"
      borderRight="0rem"
      borderLeft="0rem"
      borderBottom="1rem"
      borderColor="red.100"
    >
      <Image src={logo} width='7em'/>
      <HStack spacing="1rem" display={{ base: "none", md: "flex" }}>
        {getButtons()}
      </HStack>
      <HStack spacing="1rem">

        <Link href='https://t.me/+0MWYdZDQUCY2NTQ0' isExternal>
          <Button
          variant="solid"
          bgColor="#fff"
          borderRadius="36px"
          paddingInline={"1rem"}
          paddingY="0.5rem"
          color="#02102F"
          lineHeight="120%"
          fontWeight={750}
          fontSize={{ base: "16px" }}
          _hover={{
            bgColor: "#c5c5c5",
          }}
          size='sm'
        >
          Join Telegram
        </Button>
        </Link>
        <IconButton
          display={{
            base: "flex",
            md: "none",
          }}
          aria-label="Open menu"
          fontSize="20px"
          color="gray.800"
          _dark={{
            color: "inherit",
          }}
          variant="ghost"
          icon={<Icon as={MenuIcon} color="white" />}
          onClick={mobileNav.onOpen}
        />
      </HStack>
      {MobileNavContent}
    </HStack>
  );
}

export default Navbar;
