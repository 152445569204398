import React, { useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import Hero from "pages/landing/Hero";
import Features from "pages/landing/Features";

function App() {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      minHeight="100vh"
      position="relative"
      maxWidth="100%"
      overflowX="hidden"
      background='#0E040F'
    >
      <Hero />
      <Features />
      {/* <Testimonials /> */}
      {/* <Footer /> */}
    </Flex>
  );
}

export default App;
