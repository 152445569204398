import React from "react";
import {
  Button,
  Image,
  Stack,
  Text,
  ButtonGroup,
  Icon,
  Link,
  HStack,
  VStack,
  Container
} from "@chakra-ui/react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import bg from "../../assets/bg.png";
import starknetSvg from "../../assets/starknet.svg";
import pragmaSvg from "../../assets/pragma.svg";
import gradient from "../../assets/gradient.png";
import dice from "../../assets/dice.svg";
import { FaDiscord } from "react-icons/fa";
import TwitterIcon from "@mui/icons-material/Twitter";

function Hero() {

  function diceAnim() {
    const css = `
        @keyframes spin {
          0% { transform: translateZ(-50px) rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
          16% { transform: translateZ(-50px) rotateX(180deg) rotateY(180deg) rotateZ(0deg); }
          33% { transform: translateZ(-50px) rotateX(360deg) rotateY(90deg) rotateZ(180deg); }
          50% { transform: translateZ(-50px) rotateX(360deg) rotateY(360deg) rotateZ(360deg); }
          66% { transform: translateZ(-50px) rotateX(180deg) rotateY(360deg) rotateZ(270deg); }
          83% { transform: translateZ(-50px) rotateX(270deg) rotateY(180deg) rotateZ(180deg); }
          100% { transform: translateZ(-50px) rotateX(360deg) rotateY(360deg) rotateZ(360deg); }
      }
      @keyframes spin-duplicate {
          0% { transform: translateZ(-50px) rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
          16% { transform: translateZ(-50px) rotateX(180deg) rotateY(180deg) rotateZ(0deg); }
          33% { transform: translateZ(-50px) rotateX(360deg) rotateY(90deg) rotateZ(180deg); }
          50% { transform: translateZ(-50px) rotateX(360deg) rotateY(360deg) rotateZ(360deg); }
          66% { transform: translateZ(-50px) rotateX(180deg) rotateY(360deg) rotateZ(270deg); }
          83% { transform: translateZ(-50px) rotateX(270deg) rotateY(180deg) rotateZ(180deg); }
          100% { transform: translateZ(-50px) rotateX(360deg) rotateY(360deg) rotateZ(360deg); }
      }
      @keyframes roll {
          0% { transform: translate3d(-200px,-50px,-400px) }
          12% { transform: translate3d(0px,0,-100px) }
          25% { transform: translate3d(200px,-50px,-400px) }
          37% { transform: translate3d(0px,-100px,-800px) }
          50% { transform: translate3d(-200px,-50px,-400px) }
          62% { transform: translate3d(0px,0,-100px) }
          75% { transform: translate3d(200px,-50px,-400px) }
          87% { transform: translate3d(0px,-100px,-800px) }
          100% { transform: translate3d(-200px,-50px,-400px) }
      }
      #roll:checked ~ #platform > #dice {
          animation: spin-duplicate 2s infinite linear;
      }
      #roll:checked ~ #platform {
          width:200px;
          height:200px;
          transform-style: preserve-3d;
          animation: roll 1.6s infinite linear;
      }
      #secondroll:checked ~ #roll:checked ~ #platform > #dice,
      #secondroll:checked ~ #roll:checked ~ #platform {
          animation-play-state: paused;
      }
      #wrapper {
          position: relative;
          width: 100px;
          padding-top: 500px;
          margin: 0 auto;
          perspective: 600px;
      }
      #platform {
        margin-top:50px;
      }
      #dice span {
          position:absolute;
          margin:50px 0 0 50px;
          display: block;
          font-size: 2.5em;
          padding: 5px;
      }
      #dice {
          position: absolute;
          bottom: 10vh;
          width: 100px;
          height: 100px;
          transform-style: preserve-3d;
          animation: spin 50s infinite linear;
          z-index: 6;
      }
      .side {
          position: absolute;
          width: 100px;
          height: 100px;
          background: #fff;
          box-shadow:inset 0 0 40px #ccc;
          border-radius: 40px;
      }
      #dice .cover, #dice .inner {
          background: #e0e0e0;
          box-shadow: none;
      }
      #dice .cover {
          border-radius: 0;
          transform: translateZ(0px);
      }
      #dice .cover.x {
          transform: rotateY(90deg);
      }
      #dice .cover.z {
          transform: rotateX(90deg);
      }
      #dice .front  {
          transform: translateZ(50px);
      }
      #dice .front.inner  {
          transform: translateZ(49px);
      }
      #dice .back {
          transform: rotateX(-180deg) translateZ(50px);
      }
      #dice .back.inner {
          transform: rotateX(-180deg) translateZ(49px);
      }
      #dice .right {
          transform: rotateY(90deg) translateZ(50px);
      }
      #dice .right.inner {
          transform: rotateY(90deg) translateZ(49px);
      }
      #dice .left {
          transform: rotateY(-90deg) translateZ(50px);
      }
      #dice .left.inner {
          transform: rotateY(-90deg) translateZ(49px);
      }
      #dice .top {
          transform: rotateX(90deg) translateZ(50px);
      }
      #dice .top.inner {
          transform: rotateX(90deg) translateZ(49px);
      }
      #dice .bottom {
          transform: rotateX(-90deg) translateZ(50px);
      }
      #dice .bottom.inner {
          transform: rotateX(-90deg) translateZ(49px);
      }
      .dot {
          position:absolute;
          width:23px;
          height:23px;
          border-radius:23px;
          background:#444;
          box-shadow:inset 5px 0 10px #000;
      }
      .dot.center {
          margin:38px 0 0 38px;
      }
      .dot.dtop {
          margin-top:10px;
      }
      .dot.dleft {
          margin-left:68px;
      }
      .dot.dright {
          margin-left:8px;
      }
      .dot.dbottom {
          margin-top:67px;
      }
      .dot.center.dleft {
          margin:38px 0 0 10px;
      }
      .dot.center.dright {
          margin:38px 0 0 67px;
      }
      
      #background {
        position:fixed;
        z-index:-1;
        width:100%;
        height:100%;
        background: #071a1e;
        background: -moz-linear-gradient(top, #071a1e 0%, #274249 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#071a1e), color-stop(100%,#274249));
        background: -webkit-linear-gradient(top, #071a1e 0%,#274249 100%);
        background: linear-gradient(to bottom, #071a1e 0%,#274249 100%);
      }
      label, input[type=checkbox]:before {
        position: absolute;
        overflow:hidden;
        top:40px;
        left:40px;
        display:block;
        width:120px;
        padding-top:9px;
        height:31px;
        cursor:pointer;
        text-align:center;
        font-size:1.2em;
        font-weight:bold;
        color:#fff;
        border-radius:6px;
        border:1px solid;
      }
      input[type=checkbox]:before {
        background:transparent;
        border-color:transparent;
      }
      input[type=checkbox] {
        display:none;
      }
      label {
        opacity:.5;
        transition:all .3s ease;
      }
      label:hover {
        opacity:1;
        box-shadow:0 0 18px rgba(255,255,255,.5);
      }
      label[for=roll] {
        z-index:1;
        text-shadow:0 -1px 0 #006699;
        border-color:#17C7EC;
        background: -moz-linear-gradient(top, #0099CC 0%, #006699 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0099CC), color-stop(100%,#006699));
        background: -webkit-linear-gradient(top, #0099CC 0%,#006699 100%);
        background: linear-gradient(to bottom, #0099CC 0%,#006699 100%);
      }
      label[for=secondroll] {
        margin-top:-100px;
        text-shadow:0 -1px 0 #CC0033;
        border-color:#FF8298;
        background: -moz-linear-gradient(top, #FF6666 0%, #CC0033 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#FF6666), color-stop(100%,#CC0033));
        background: -webkit-linear-gradient(top, #FF6666 0%,#CC0033 100%);
        background: linear-gradient(to bottom, #FF6666 0%,#CC0033 100%);
      }
      #roll:checked ~ label[for=roll] {
        margin-top:-100px;
      }
      #roll:checked ~ label[for=secondroll] {
        margin-top:0px;
      }
      #secondroll:checked ~ label[for=secondroll] {
        text-shadow:0 -1px 0 #336633;
        border-color:#66FF99;
        background: -moz-linear-gradient(top, #66CC66 0%, #336633 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#66CC66), color-stop(100%,#336633));
        background: -webkit-linear-gradient(top, #66CC66 0%,#336633 100%);
        background: linear-gradient(to bottom, #66CC66 0%,#336633 100%);
      }
      #secondroll:checked ~ label[for=secondroll] span {
        display:none;
      }
      #secondroll:checked ~ label[for=secondroll]:after {
        content:"Keep rollin'!";
      }
    `
    return (<div id="dice">
      <style>
          {css}
      </style>
    <div className="side front">
      <div className="dot center"></div>
    </div>
    <div className="side front inner"></div>
    <div className="side top">
      <div className="dot dtop dleft"></div>
      <div className="dot dbottom dright"></div>
    </div>
    <div className="side top inner"></div>
    <div className="side right">
      <div className="dot dtop dleft"></div>
      <div className="dot center"></div>
      <div className="dot dbottom dright"></div>
    </div>
    <div className="side right inner"></div>
    <div className="side left">
      <div className="dot dtop dleft"></div>
      <div className="dot dtop dright"></div>
      <div className="dot dbottom dleft"></div>
      <div className="dot dbottom dright"></div>
    </div>
    <div className="side left inner"></div>
    <div className="side bottom">
      <div className="dot center"></div>
      <div className="dot dtop dleft"></div>
      <div className="dot dtop dright"></div>
      <div className="dot dbottom dleft"></div>
      <div className="dot dbottom dright"></div>
    </div>
    <div className="side bottom inner"></div>
    <div className="side back">
      <div className="dot dtop dleft"></div>
      <div className="dot dtop dright"></div>
      <div className="dot dbottom dleft"></div>
      <div className="dot dbottom dright"></div>
      <div className="dot center dleft"></div>
      <div className="dot center dright"></div>
    </div>
    <div className="side back inner"></div>
    <div className="side cover x"></div>
    <div className="side cover y"></div>
    <div className="side cover z"></div>
  </div>)
  }
  return (
    <VStack
      justifyContent="center"
      minHeight="100vh"
      width="100%"
      position="relative"
    >
      <Container
        position={'absolute'}
        width='100%'
        height='100vh'
        maxWidth='100%'
        filter='brightness(0.5)'
        backgroundImage={bg}
        backgroundPosition='0% 35%'
        backgroundSize='cover'
        backgroundRepeat='no-repeat'
        backgroundColor='rgba(0, 0, 0, 0.5)'>
        
      </Container>
      <Container
        zIndex="3"
        width='100%'
        maxWidth='100%'
        position='relative'
        height='auto'
        padding='0'
        marginTop='100px !important'
      >
        <Text
          lineHeight={["3.5rem", "4.25rem", "4.25rem", "5.5rem"]}
          color="white"
          width='100%'
          fontWeight='900'
          maxWidth='90%'
          margin='0 auto !important'
          backgroundClip='text'
          fontSize={{
            base: "2.5rem",
            md: "3.5rem",
            lg: "4.25rem",
            xl: "4.5rem",
            "2xl": "5rem",
          }}
          zIndex="3"
          minHeight='auto'
          textShadow='0px 12px 5.4px rgba(0, 0, 0, 0.25)'
        >
          LAUNCHING SOON!
        </Text>
        <Container
          margin='0 auto'
          height='1px'
          maxWidth='100%'
          padding='0'
        >
          <Text
            position='absolute'
            top='0'
            lineHeight={["3.5rem", "4.25rem", "4.25rem", "5.5rem"]}
            width='9.8em'
            maxWidth='90%'
            margin='0 auto !important'
            color="white"
            fontWeight='900'
            background='linear-gradient(91deg, #1b0811 -24.72%, #FFF 18.27%, #FFF 83.35%, #1e0810 135.64%)'
            backgroundClip='text'
            left='0'
            right='0'
            // fontSize={"6.5rem"}
            fontSize={{
              base: "2.5rem",
              md: "3.5rem",
              lg: "4.25rem",
              xl: "4.5rem",
              "2xl": "5rem",
            }}
            zIndex="4"
          >
            LAUNCHING SOON!
          </Text>
        </Container>
      </Container>
      <Text
          lineHeight="30px"
          color="white"
          width='60%'
          fontWeight='bold'
          margin='0px 20% 0 !important'
          fontSize={{
            base: "0.85rem",
            md: "1rem",
            lg: "1.1rem",
            xl: "1.25rem",
            "2xl": "1.25rem",
          }}
          zIndex="3"
        >
          Inject some dicey excitement with zkDice on Starknet! Let's roll the dice
        </Text>
      {/* <Container height='100px'/> */}
      <Text
        lineHeight="normal"
        color="white"
        width='80%'
        margin='50px 10% 0 !important'
        fontWeight='bold'
        fontSize={{
          base: "0.55rem",
          md: "0.75rem",
          lg: "0.85rem",
          xl: "0.90rem",
          "2xl": "1rem",
        }}
        zIndex="3"
      >
        Powered by
      </Text>
      <Stack spacing={['12px', '24px']} direction={['column', 'row']} zIndex="7">
        <Link href='https://www.starknet.io/' isExternal>
          <Button background='rgb(31 9 19 / 80%)' padding='2rem 2.5rem' borderRadius='20px' _hover={{ bg: 'rgb(31 9 19 / 95%)' }} size='sm'>
            <Image src={starknetSvg} height='30px'/>
          </Button>
        </Link>
        <Link href='https://www.pragmaoracle.com/' isExternal>
          <Button background='rgb(31 9 19 / 80%)' padding='2rem 2.5rem' borderRadius='20px' _hover={{ bg: 'rgb(31 9 19 / 95%)' }}>
            <Image src={pragmaSvg}  height='21px'/>
          </Button>
        </Link>
      </Stack>
      <Container height='130px'/>
      {diceAnim()}
      {/* <Image
        position="absolute"
        bottom="0"
        // left="0"
        marginTop="auto"
        src={dice}
        width={{
          base: "200px",
          md: "300px",
        }}
        zIndex="5"
      /> */}
    </VStack>
  );
}

export default Hero;
